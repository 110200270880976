<script>
import DropZone from "@/components/widgets/dropZone";
import { ref } from "vue";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from 'sweetalert2'
//import {  toRaw } from 'vue';

import gallerySaveOrUpdate from "../../../state/api/gallerySaveOrUpdate";
import galleryGetFindByTypeIdAndTypeName from "../../../state/api/galleryGetFindByTypeIdAndTypeName";
import galleryDelete from "../../../state/api/galleryDelete";
/**
 * File Uploads component
 */
export default {
  page: {
    title: "Upload",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { DropZone, Layout, PageHeader },
    setup() {
    let dropzoneFile = ref("");
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
    };
    return { dropzoneFile, drop, selectedFile };
  },
  data() {
    return {
      title: "Product Gallery",
      items: [
        {
          text: "Upload",
          href: "/"
        },
        {
          text: "Product Gallery",
          active: true
        }
      ],
      galleryList:[],
      gallery:{
        FileImage:"",
        Title:"",
        ShortDescription:"",
        id:this.$route.params.id,
        TypeName:"Product"
      },
      images:{
        image:""
      }
    };
  },
  methods:{
    async galleryGetAll(){
          const {galleryGetFindById} = galleryGetFindByTypeIdAndTypeName()
          const response = await galleryGetFindById(this.gallery.id, "Product");
          if(response){
            this.galleryList = response.data;
          }
          
    },
    addGallery(){
        let dropzoneFile = ref("");
        const data = this;
        dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
        let update = {
                "TypeId" : this.gallery.id,
                "TypeName" : "Product",
                "Title" : this.gallery.Title,
                "ShortDescription" : this.gallery.ShortDescription,
                "CompanyName" : "test ozmo",
        }
        if(update.Title ==""){
          Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Please write the name of the picture.",
              showConfirmButton: false,
              timer: 1500
          })
        }
        else{
          if(dropzoneFile.value.type =="image/png" || dropzoneFile.value.type =="image/jpg" || dropzoneFile.value.type =="image/jpeg"){            
          
            const reader = new FileReader();
            reader.readAsDataURL(dropzoneFile.value);
            reader.onload = function() {
                let base64 = reader.result;
                addGallery(base64);
            };
          }
          else{
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Please upload images with jpg or png extension.",
                showConfirmButton: false,
                timer: 1500
            })
          }
        }
        async function addGallery(images) {
            const {SaveOrUpdate} = gallerySaveOrUpdate()
            await SaveOrUpdate(update,images).
            then(res=>{
                if(res.isSuccess){
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Your work has been saved",
                        showConfirmButton: false,
                        timer: 1500
                    })
                    data.gallery.FileImage=""
                    data.gallery.Title=""
                    data.galleryGetAll();
                }
            })
        }
    },
    galleryDelete(id){
            const data = this.galleryList;
            let TypeId = this.gallery.id
            async function postGalleryDelete() {
                const {postGalleryDelete} = galleryDelete()
                await postGalleryDelete(id,TypeId,"Product").
                then(res=>{
                    if(res.isSuccess){
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        })
                        data.filter(x=>x.id == id)[0].isDelete =true
                    }
                })
            }
            postGalleryDelete();
    },
  },
  mounted(){
      this.galleryGetAll();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
                <div class="col-lg-6">
                  <span class="file-info d-block mb-2 mt-1">File: {{ dropzoneFile.name }}</span>
                    <!-- file upload -->
                    <DropZone @drop.prevent="drop" @change="selectedFile" />
                </div>
                <div class="col-lg-6">
                    <!-- <textarea id="outputBase64" class="o-hidden" rows="10" cols="80" v-model="FileImage" readonly></textarea> -->
                    <div class="form-group row mb-3 px-4">
                        <label class="col-form-label ps-0" for="Title"
                            >{{$t('user.Title')}}</label>
                        <input
                            type="text"
                            name="Title"
                            class="form-control"
                            v-model="gallery.Title"
                            />
                    </div>
                    <div class="form-group row mb-3 px-4">
                        <label class="col-form-label ps-0" for="ShortDescription"
                            >{{$t('user.shortDescription')}}</label>
                        <input
                            type="text"
                            name="ShortDescription"
                            class="form-control"
                            v-model="ShortDescription"
                            />
                    </div>
                </div>
            </div>
            <div class="text-left mt-4 mb-5">
              <p><strong>{{$t('user.productImageWarning')}}</strong></p>
              <button type="button" class="btn btn-primary" @click="addGallery()">Send Files</button>
            </div>
            <hr>
            <div class="row mt-5">
                <div class="col-lg-6 col-xl-3 mb-3" v-for="gallery in galleryList.filter(x=>!x.isDelete)" :key="gallery.id">
                    <!-- Simple card -->
                    <b-card :img-src="gallery.path" :img-alt="gallery.title" img-top>
                        <b-card-title>
                            <div class="d-flex justify-content-between">
                              <h5 class="card-title">{{gallery.title}}</h5>
                              <button v-b-tooltip.hover :title="$t('user.Delete')" class="text-danger fs-3 border-0 bg-transparent" @click="galleryDelete(gallery.id)">
                                    <i class="bx bx-trash-alt"></i>
                              </button>
                            </div>
                        </b-card-title>
                    </b-card>
                </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>    
    <!-- end row -->
  </Layout>
</template>